







import { Component, Vue } from 'vue-property-decorator'
import personalInfo from '@/components/personal/info.vue'
import { Getter, Action } from 'vuex-class'
import bus from '@/common/bus'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    personalInfo
  },
  mixins: [globalMixins]
})
export default class PersonalInfo extends Vue<globalMixins> {
  @Getter('account') account: Object
  @Action('profile') profile: Function
  @Action('profileUpdate') profileUpdate: Function

  get info (): any[] {
    let arr: any[] = []
    arr.push(this.account)
    return arr
  }

  mounted () {
    this.profile()
  }

  doDetail (info) {
    let params = JSON.parse(info)
    this.profileUpdate({ params }).then(() => {
      this.successMsg('个人信息修改成功!')
      this.profile().then(() => {
        localStorage.setItem('account', JSON.stringify(this.account))
        bus.$emit('accountChange')
      })
    }).catch((err) => {
      this.errorMsg('个人信息修改失败!')
    })
  }
}
