










































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop } from "vue-property-decorator"

@Component
export default class PersonalInfo extends Vue {
  editing: boolean = false
  @Prop()
  info: any[]

  formData: Object = {}
  edit () {
    this.editing = true
  }

  cancel () {
    this.editing = false
    this.formData = Object.assign({}, this.info[0])
  }

  save () {
    let info = JSON.stringify(this.formData)
    let formData = this.$refs.formData as HTMLFormElement
    formData.validate((valid) => {
      if (valid) {
        this.editing = false
        this.$emit('updateMemberInfo', info)
      } else {
        return false
      }
    })
  }

  @Watch('info')
  onPersonalInfoChange (val: any[], old: any[]) {
    this.formData = Object.assign({}, val[0])
  }
}
